function _isDefined(i,e=window){try{return void 0!==i.split(".").reduce((i,e)=>i&&void 0!==i[e]?i[e]:void 0,e)}catch(r){return!1}}

(function() {
    document.addEventListener('click', function(e) {
        const target = e.target.closest('.next-button, .back-button, .hamburger-button');

        if (!target) return;

        e.preventDefault();

        if (target.classList.contains('next-button')) {
            handleNextStep(target.closest('.step'));
        }

        if (target.classList.contains('back-button')) {
            handleBackStep(target.closest('.step'));
        }

        if (target.classList.contains('hamburger-button')) {
            handleHamburgerMenu();
        }
    });

    const form = document.getElementById('myForm');
    const steps = Array.from(document.querySelectorAll('.step'));
    const phoneMasks = document.getElementsByName("phone");

    const handleNextStep = (currentStep) => {
        const currentStepInputs = currentStep.querySelectorAll('input, select');
        const nextStep = steps[steps.indexOf(currentStep) + 1];

        if (areInputsValid(currentStepInputs)) {
            transitionStep(currentStep, nextStep);
            fireNextStepEvent();
        } else {
            triggerSubmit();
        }
    }

    const areInputsValid = (inputs) => {
        return Array.from(inputs).every(input => {
            const value = input.value.trim();
            if (!value) return false;

            const pattern = input.getAttribute('pattern');
            return pattern ? new RegExp(pattern).test(value) : true;
        });
    }

    const transitionStep = (currentStep, nextStep) => {
        currentStep.classList.remove('is-active');
        fadeOut(currentStep, 200, () => fadeIn(nextStep, 200));
    }

    const triggerSubmit = () => {
        form.querySelector("button[type=submit]").click();
    }

    const formatPhoneNumber = (e) => {
        const x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        e.target.value = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? '-' + x[3] : ''}`;
    }

    const handleBackStep = (currentStep) => {
        const previousStep = steps[steps.indexOf(currentStep) - 1];
        transitionStep(currentStep, previousStep);
    }

    const handleSubmit = (form) => {
        const currentStep = form.querySelector('.step[data-step="2"]');
        const currentStepInputs = currentStep.querySelectorAll('input, select');
        const formData = new FormData(form);

        formData.set('micro_user_id', getCookie('mixp_id'));
        formData.set('micro_session_id', getCookie('mixp_session_id'));

        if (areInputsValid(currentStepInputs)) {
            splitFullName(formData);
            fireFormAttemptEvent(formData);
            sendDataToWebhook(formData);
        } else {
            triggerSubmit();
        }
    }

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    const splitFullName = (formData) => {
        const fullName = formData.get('full_name');
        const [firstName, lastName] = fullName.split(' ');
        formData.set('first_name', firstName);
        formData.set('last_name', lastName);
    }

    const sendDataToWebhook = (formData) => {
        fetch('https://hooks.zapier.com/hooks/catch/3626579/2mdi8eh/', {
            method: 'POST',
            body: formData
        })
        .then(() => {
            fireFormSubmitSuccessEvent();
            window.location.href = '/thank-you.html';
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    const isMixpanelAvailable = () => {
        return _isDefined('mixpanel') && _isDefined('track', mixpanel);
    };

    const fireMixpanelEvent = (eventName, data = {}) => {
        if (isMixpanelAvailable()) {
            mixpanel.track(eventName, data);
        }
    };

    const setMixpanelPeopleData = (formData) => {
        if (formData.get("full_name")) {
            mixpanel.people.set({ $name: formData.get("full_name") });
        }
        if (formData.get("email")) {
            mixpanel.people.set({ $email: formData.get("email") });
        }
        if (formData.get("phone")) {
            mixpanel.people.set({ $phone: formData.get("phone") });
        }
    };

    const fireFormAttemptEvent = (formData) => {
        if (isMixpanelAvailable() && formData.entries()) {
            fireMixpanelEvent("Form Submit Attempt", Object.fromEntries(formData.entries()));
            setMixpanelPeopleData(formData);
        }
    };

    const fireFormSubmitSuccessEvent = () => {
        fireMixpanelEvent("Form Submit Success");
    };

    const fireNextStepEvent = () => {
        fireMixpanelEvent("Form", { action: "Next" });
    };

    const fireFormImpressionEvent = () => {
        fireMixpanelEvent("Form", { action: "Impression" });
    };

    const fadeOut = (element, time, cb) => {
        let op = 1;
        const timer = setInterval(() => {
            if (op <= 0.1) {
                clearInterval(timer);
                element.style.display = 'none';
                cb();
            }
            element.style.opacity = op;
            op -= 50 / time;
        }, 50);
    }

    const fadeIn = (element, time, cb) => {
        let op = 0;
        element.style.display = 'block';
        const timer = setInterval(() => {
            if (op >= 1) {
                clearInterval(timer);
                cb();
            }
            element.style.opacity = op;
            op += 50 / time;
        }, 50);
    }

    const handleHamburgerMenu = () => {
        const navigation = document.querySelector('.nav');
        const hamburgerButton = document.querySelector('.hamburger-button');
        const isExpanded = hamburgerButton.getAttribute('aria-expanded') === 'true';
    
        hamburgerButton.setAttribute('aria-expanded', !isExpanded);
        navigation.setAttribute('aria-expanded', !isExpanded);
    
        navigation.classList.toggle('is-active');
        hamburgerButton.querySelector('.hamburger').classList.toggle('is-active');
    }

    phoneMasks.forEach(phoneMask => {
        phoneMask.addEventListener('input', formatPhoneNumber);
    });

    document.addEventListener('DOMContentLoaded', () => {
        if (form) {
            fireFormImpressionEvent();

            form.addEventListener('submit', (e) => {
                e.preventDefault();
                handleSubmit(e.target);
            });
        }
    });
})();
